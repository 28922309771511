import * as React from "react";
import styled from "styled-components";
import logo from "../assets/logoaew.png";

const SLogoalteregoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SLogoalterego = styled.div`
  width: 275px;
  height: 45px;
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Logoalterego = () => (
  <SLogoalteregoWrapper>
    <SLogoalterego />
  </SLogoalteregoWrapper>
);

export default Logoalterego;